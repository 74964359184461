import { on, off } from './shortcuts';
import shared from '../modules/shared';

let isBodyLocked = false;
let lockedElements = [];

const preventScrollEvent = (event) => {
  const e = event || window.event;

  if (e.touches.length > 1) return true;

  if (e.preventDefault) {
    e.preventDefault();
  }

  return false;
};

const scrollHandler = (event, data) => {
  const clientY = event.targetTouches[0].clientY - data.initialY;

  if (data.el && data.el.scrollTop === 0 && clientY > 0) {
    return preventScrollEvent(event);
  }

  if (
    (data.el
      ? data.el.scrollHeight - data.el.scrollTop <= data.el.clientHeight
      : false) &&
    clientY < 0
  ) {
    return preventScrollEvent(event);
  }

  event.stopPropagation();
  return true;
};

const stopBodyScroll = () => {
  if (!isBodyLocked) {
    document.addEventListener(
      'touchmove',
      preventScrollEvent,
      shared.hasPassiveEvents ? { passive: false } : undefined,
    );

    isBodyLocked = true;
  }
};

const resumeBodyScroll = () => {
  if (isBodyLocked) {
    document.removeEventListener(
      'touchmove',
      preventScrollEvent,
      shared.hasPassiveEvents ? { passive: false } : undefined,
    );
    isBodyLocked = false;
  }
};

const unlockViewport = (el) => {
  document.documentElement.classList.remove(shared.CSS.locked);

  if (shared.isTouch) {
    const newLockedElements = [];

    for (let i = 0; i < lockedElements.length; i += 1) {
      if (lockedElements[i].el === el) {
        off('touchstart', el, lockedElements[i].touchStart);
        off('touchmove', el, lockedElements[i].touchMove);
      } else {
        newLockedElements.push(lockedElements[i]);
      }
    }

    if (newLockedElements.length === 0) {
      resumeBodyScroll();
    }

    lockedElements = newLockedElements;
  }
};

const lockViewport = (el) => {
  for (let i = 0; i < lockedElements.length; i += 1) {
    if (lockedElements[i].el === el) {
      return;
    }
  }

  document.documentElement.classList.add(shared.CSS.locked);

  if (shared.isTouch) {
    if (lockedElements.length === 0) {
      stopBodyScroll();
    }

    const data = {
      el,
      touchStart: (event) => {
        if (event.targetTouches.length === 1) {
          data.initialY = event.targetTouches[0].clientY;
        }
      },
      touchMove: (event) => {
        if (event.targetTouches.length === 1) {
          scrollHandler(event, data);
        }
      },
    };

    lockedElements.push(data);

    on('touchstart', el, data.touchStart);
    on('touchmove', el, data.touchMove);
  }
};

document.documentElement.style.setProperty(
  '--scroll-width',
  `${window.innerWidth - document.documentElement.clientWidth}px`,
);

export { lockViewport, unlockViewport };
