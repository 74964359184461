import shared from './shared';

const perpareAnnotationMarks = () => {
  const annotationMarks = document.querySelectorAll('.c-annotation-mark');

  if (annotationMarks.length < 1) {
    return;
  }

  let currentAnnotation = null;

  const closeCurrentAnnotation = () => {
    if (currentAnnotation) {
      currentAnnotation.classList.remove('is-open');
      currentAnnotation = null;
    }
  };

  const calculateAnnotationPositions = () => {
    if (shared.windowWidth < shared.breakpoints.large) {
      return;
    }

    annotationMarks.forEach((annotationMark) => {
      const { left } = annotationMark.getBoundingClientRect();

      annotationMark.classList.toggle(
        'c-annotation-mark--right',
        left / shared.windowWidth > 0.55,
      );
    });
  };

  annotationMarks.forEach((annotationMark) => {
    const link = annotationMark.children[0];
    const popup = annotationMark.querySelector('.c-annotation-mark__popup');
    const popupClose = annotationMark.querySelector(
      '.c-annotation-mark__close',
    );

    link.addEventListener('click', (event) => {
      event.preventDefault();
      event.stopPropagation();

      if (!shared.isTouch) {
        return;
      }

      annotationMark.classList.toggle('is-open');
      closeCurrentAnnotation();

      if (annotationMark.classList.contains('is-open')) {
        currentAnnotation = annotationMark;
      }
    });

    popup.addEventListener('click', (event) => {
      event.stopPropagation();
    });

    popupClose.addEventListener('click', (event) => {
      event.stopPropagation();
      closeCurrentAnnotation();
    });
  });

  document.body.addEventListener('click', () => {
    if (!shared.isTouch) {
      return;
    }

    closeCurrentAnnotation();
  });

  calculateAnnotationPositions();

  window.addEventListener('load', calculateAnnotationPositions);
  window.addEventListener('resize', calculateAnnotationPositions);
};

export default () => {
  perpareAnnotationMarks();

  const annotation = document.querySelector('.c-annotation');

  if (!annotation) {
    return;
  }

  const annotationTitle = annotation.querySelector('.c-annotation__title');
  const annotationItems = annotation.querySelector('.c-annotation__items');

  annotationTitle.addEventListener('click', () => {
    annotationItems.style.maxHeight = `${annotationItems.scrollHeight}px`;

    if (annotation.classList.contains('is-open')) {
      // eslint-disable-next-line no-unused-expressions
      annotationItems.offsetWidth;
      annotationItems.style.maxHeight = '';
    }

    annotation.classList.toggle('is-open');
  });

  annotationItems.addEventListener('transitionend', () => {
    if (annotation.classList.contains('is-open')) {
      annotationItems.style.maxHeight = '';
    }
  });
};
