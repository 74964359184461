import { on } from '../helpers/shortcuts';
import shared from './shared';

export default () => {
  const anchorTriggers = document.querySelectorAll('[href*="#"]');

  if (anchorTriggers.length < 1) {
    return;
  }

  [...anchorTriggers].forEach((anchor) => {
    on('click', anchor, (event) => {
      const id = event.currentTarget.href.split('#').pop();
      const target = document.getElementById(id);

      if (
        event.currentTarget.parentNode.classList.contains('c-annotation-mark')
      ) {
        return;
      }

      if (target) {
        event.preventDefault();
        const top =
          target.getBoundingClientRect().top +
          shared.scrollTop -
          shared.headerHeight -
          shared.adminBarHeight -
          30;
        window.scrollTo({ top, left: 0, behavior: 'smooth' });
      }
    });
  });
};
