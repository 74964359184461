import Swiper, { EffectCoverflow } from 'swiper';
import shared from './shared';
import { createEvent } from '../helpers/shortcuts';

export default () => {
  const workGalleryOverlay = document.querySelector('.c-overlay--work-gallery');

  if (!workGalleryOverlay) {
    return;
  }

  const galleryTriggers = document.querySelectorAll(
    '.wp-block-image__gallery-trigger-button',
  );
  const swiperContainer = workGalleryOverlay.querySelector('.swiper');
  const workGallery = workGalleryOverlay.querySelector('.c-work-gallery');
  const next = workGallery.querySelector('.c-work__gallery-nav--next');
  const prev = workGallery.querySelector('.c-work__gallery-nav--prev');
  const headerClose = document.querySelector('.c-header__close');
  const slides = workGallery.querySelectorAll('.c-work-gallery__slide');
  let swiper = null;

  const addSlideClasses = (swiperInstance) => {
    swiperInstance.slides.forEach((slide, index) => {
      if (index < swiperInstance.activeIndex) {
        slide.classList.add('is-prev');
      } else if (index > swiperInstance.activeIndex) {
        slide.classList.add('is-next');
      } else {
        slide.classList.remove('is-next', 'is-prev');
      }
    });

    workGallery.classList.remove('is-first', 'is-last');

    if (swiperInstance.isBeginning) {
      workGallery.classList.add('is-first');
    }

    if (swiperInstance.isEnd) {
      workGallery.classList.add('is-last');
    }
  };

  const updateCaptionHeights = () => {
    [...slides].forEach((slide) => {
      const captionHeight =
        slide.children.length > 1 ? slide.lastElementChild.scrollHeight : 0;
      slide.style.setProperty('--caption-height', `${captionHeight}px`);
    });
  };

  [...galleryTriggers].forEach((trigger, index) => {
    trigger.addEventListener('click', (event) => {
      event.preventDefault();

      workGalleryOverlay.classList.add(shared.CSS.active);
      updateCaptionHeights();

      if (!swiper) {
        swiper = new Swiper(swiperContainer, {
          effect: 'coverflow',
          grabCursor: true,
          centeredSlides: true,
          slidesPerView: 'auto',
          modules: [EffectCoverflow],
          coverflowEffect: {
            rotate: 0,
            stretch: window.innerWidth * 0.05,
            depth: 0,
            scale: 0.9,
            slideShadows: false,
          },
          on: {
            afterInit: addSlideClasses,
            slideChange: addSlideClasses,
          },
        });
      }

      swiper.slideTo(index, 0);
    });
  });

  if (window.galleryOverlay && galleryTriggers[0]) {
    galleryTriggers[0].dispatchEvent(createEvent('click'));
  }

  next.addEventListener('click', (event) => {
    event.preventDefault();
    if (swiper) {
      swiper.slideNext();
    }
  });

  prev.addEventListener('click', (event) => {
    event.preventDefault();
    if (swiper) {
      swiper.slidePrev();
    }
  });

  if (headerClose) {
    headerClose.addEventListener('click', (event) => {
      if (workGalleryOverlay.classList.contains(shared.CSS.active)) {
        event.preventDefault();
        event.stopPropagation();
        workGalleryOverlay.classList.remove(shared.CSS.active);
      }
    });
  }

  const updateGallery = () => {
    if (workGalleryOverlay.classList.contains(shared.CSS.active)) {
      updateCaptionHeights();
    }
  };

  window.addEventListener('resize', updateGallery);
  //window.addEventListener('load', updateGallery);
  //document.addEventListener('DOMContentLoaded', updateGallery);
};
