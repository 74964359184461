import { on } from '../helpers/shortcuts';

export default () => {
  const form = document.querySelector('.c-footer__newsletter-form');

  if (form === null) {
    return;
  }

  let isSubmitting = false;
  const submit = form.querySelector('.c-footer__newsletter-submit');
  const message = form.querySelector('.c-footer__newsletter-message');
  const agree = form.querySelector('.c-footer__newsletter-agree-input');
  const submitText = submit.textContent;

  const jsonop = (url) =>
    new Promise((rs) => {
      const script = document.createElement('script');
      const name = `_jsonp_${new Date().getTime()}`;

      if (url.match(/\?/)) {
        // eslint-disable-next-line no-param-reassign
        url += `&c=${name}`;
      } else {
        // eslint-disable-next-line no-param-reassign
        url += `?c=${name}`;
      }

      script.src = url;
      window[name] = (json) => {
        rs(new Response(JSON.stringify(json)));
        script.remove();
        delete window[name];
      };

      document.body.appendChild(script);
    });

  on('submit', form, (event) => {
    event.preventDefault();

    if (agree && !agree.checked) {
      form.classList.add('is-error');
      return;
    }

    if (isSubmitting) {
      return;
    }

    isSubmitting = true;
    submit.textContent = submit.dataset.loadingLabel;

    const formData = new FormData(form);
    const body = {};
    formData.forEach((value, key) => {
      body[key] = value;
    });

    const url = `${form
      .getAttribute('action')
      .replace('/post?', '/post-json?')}&${new URLSearchParams(
      body,
    ).toString()}`;

    jsonop(url)
      .then((response) => response.json())
      .then((data) => {
        let update = false;

        if (data.msg !== undefined && data.msg.indexOf('already') > -1) {
          update = true;
        }

        if (data.result === 'success' || update) {
          message[update ? 'innerHTML' : 'textContent'] = data.msg.replace(
            '<a ',
            '<a target="_blank" rel="noopener noreferrer" ',
          );
          form.reset();
          form.classList.add('is-submitted');
          setTimeout(() => {
            form.classList.remove('is-submitted');
            form.classList.remove('is-error');
            message.innerHTML = '';
          }, 8000);
        } else if (data.result === 'error') {
          message.innerHTML =
            data.msg.indexOf('Please enter a value') > -1
              ? 'Please fill in all required fields'
              : data.msg;
        } else {
          message.innerHTML =
            'We could not subscribe your e-mail address, please try later';
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log('Newsletter submit:', error);
        message.innerHTML =
          'We encountered server error, please contact administrator to resolve the issue';
      })
      .finally(() => {
        isSubmitting = false;
        submit.textContent = submitText;
      });
  });
};
