export default () => {
  const showMoreAuthors = document.querySelector('.c-work__meta-more');

  if (!showMoreAuthors) {
    return;
  }

  showMoreAuthors.addEventListener('click', (event) => {
    event.preventDefault();

    showMoreAuthors.parentElement.classList.toggle('show-all');

    const label = showMoreAuthors.textContent;
    showMoreAuthors.textContent = showMoreAuthors.dataset.label;
    showMoreAuthors.dataset.label = label;
  });
};
