import { each } from '../helpers/shortcuts';
import swipeSlider from '../helpers/swipe-slider';
import shared from './shared';

export default () => {
  const scrollContainers = document.querySelectorAll(
    '.js-horizontal-scroll-pane',
  );

  if (scrollContainers.length < 1) {
    return;
  }

  each(scrollContainers, (scrollContainer) => {
    if (!shared.isMobile) {
      swipeSlider(scrollContainer);
    }
  });
};
