import { on } from '../helpers/shortcuts';
import shared from './shared';

export default () => {
  const submenuTriggers = document.querySelectorAll(
    '.menu-item-has-children.c-footer__menu-item > .c-footer__menu-link',
  );

  if (submenuTriggers.length < 1) {
    return;
  }

  [...submenuTriggers].forEach((trigger) => {
    on('click', trigger, (event) => {
      event.preventDefault();

      if (shared.windowWidth < shared.breakpoints.medium) {
        trigger.parentElement.classList.toggle(shared.CSS.active);
      }
    });
  });
};
