import { on, createEvent, each, off } from '../helpers/shortcuts';

let scrollTimeout = null;

const shared = {
  isTouch: false,
  isMobile:
    typeof window.orientation !== 'undefined' ||
    navigator.userAgent.indexOf('IEMobile') !== -1,
  scrollTop: window.scrollY === undefined ? window.pageYOffset : window.scrollY,
  windowWidth: window.innerWidth,
  windowHeight: window.innerHeight,
  initialHeight: window.innerHeight,
  hasPassiveEvents: false,
  isScrolling: false,
  isScrollAnimating: false,
  adminBarHeight: 0,
  headerInner: document.querySelector('.c-header__inner'),
  headerHeight: 0,
  CSS: {
    fixed: 'is-fixed',
    active: 'is-active',
    menuOpen: 'is-menu-open',
    searchOpen: 'is-search-open',
    loading: 'is-loading',
    hidden: 'is-hidden',
    filterOpen: 'is-filter-open',
    overlayOpen: 'is-overlay-open',
    galleryOpen: 'is-gallery-open',
    locked: 'is-locked',
    invisible: 'is-invisible',
    visible: 'is-visible',
    loaded: 'is-loaded',
    animating: 'is-animating',
    submenuFixed: 'is-submenu-fixed',
    submenuOpen: 'is-submenu-open',
    open: 'is-open',
    displayed: 'is-displayed',
    invalid: 'is-invalid',
  },
  breakpoints: {
    small: 480,
    full: 1400,
    xlarge: 1200,
    large: 960,
    medium: 720,
  },
  firstScroll: !(window.location.hash.length > 0),
  hasInteracted: false,
};

/* eslint-disable */
try {
  document.addEventListener('test', null, {
    get passive() {
      shared.hasPassiveEvents = true;
    },
  });
} catch (e) {
}
/* eslint-enable */

shared.getHeaderHeight = () => {
  if (shared.headerInner) {
    return shared.headerInner.getBoundingClientRect().height;
  }

  return 0;
};

const documentTouchstart = () => {
  shared.isTouch = true;
  document.documentElement.classList.remove('no-touch');
  document.documentElement.classList.add('is-touch');
  off('touchstart', document, documentTouchstart);
  on('touchstart', document.body, () => {});
  document.dispatchEvent(createEvent('touchdetected'));
};

on('touchstart', document, documentTouchstart);

const interaction = () => {
  shared.hasInteracted = true;
  document.documentElement.classList.remove('no-interaction');
  document.documentElement.classList.add('is-interaction');
  off('touchstart wheel mousedown', document, interaction);
  document.dispatchEvent(createEvent('interaction'));
};

on('touchstart wheel mousedown', document, interaction);

each(document.querySelectorAll('[href="#"]'), (link) => {
  on('click', link, (event) => event.preventDefault());
});

on('resize', window, () => {
  shared.windowWidth = window.innerWidth;
  shared.windowHeight = window.innerHeight;
});

on('scroll', window, () => {
  shared.isScrolling = true;
  shared.scrollTop = Math.max(
    window.scrollY === undefined ? window.pageYOffset : window.scrollY,
    0,
  );

  clearTimeout(scrollTimeout);
  scrollTimeout = setTimeout(() => {
    shared.isScrolling = false;
    shared.firstScroll = false;
  }, 300);
});

if (shared.isMobile) {
  document.documentElement.classList.add('is-mobile');
} else {
  document.documentElement.classList.add('is-desktop');
}

export default shared;
