import shared from './shared';

function validateNIP(nip) {
  const nipWithoutDashes = nip.replace(/-/g, '');
  const reg = /^[0-9]{10}$/;
  if (
    reg.test(nipWithoutDashes) === false ||
    parseInt(nipWithoutDashes.replace(/[^0-9]/g, ''), 10) < 1
  ) {
    return false;
  }

  const digits = `${nipWithoutDashes}`.split('');
  const checksum =
    (6 * parseInt(digits[0], 10) +
      5 * parseInt(digits[1], 10) +
      7 * parseInt(digits[2], 10) +
      2 * parseInt(digits[3], 10) +
      3 * parseInt(digits[4], 10) +
      4 * parseInt(digits[5], 10) +
      5 * parseInt(digits[6], 10) +
      6 * parseInt(digits[7], 10) +
      7 * parseInt(digits[8], 10)) %
    11;

  return checksum !== 10 && parseInt(digits[9], 10) === checksum;
}

export default () => {
  const register = document.querySelector('.c-register');

  if (register === null) {
    return;
  }

  const overlay = register.querySelector('.c-register__overlay');
  const registerForm = register.querySelector('.c-register__form');
  const registerPages = [
    ...registerForm.querySelectorAll('.c-register__page'),
    overlay,
  ];
  const productRows = register.querySelectorAll('.c-register__product-row');
  let currentPage = 0;
  const nextButton = register.querySelector('[data-next]');
  const invoice = register.querySelector('input[name="invoice"]');
  const invoiceData = register.querySelector('.c-register__invoice-data');
  const addressInputs = register.querySelectorAll('.c-register__address input');
  const breadcrumbsContainer = register.querySelector('.c-register__steps');
  const breadcrumbs = [
    ...breadcrumbsContainer.querySelectorAll('.c-register__steps-step'),
  ];
  const invoiceInputs = invoiceData.querySelectorAll('input');
  const agree = register.querySelectorAll('.c-register__agree input');
  const backButtons = register.querySelectorAll('[data-back]');
  const moreTriggers = register.querySelectorAll('.c-register__agree-trigger');
  const isInOverlay = register.parentElement.classList.contains('c-overlay');

  const scrollToElement = (el) => {
    setTimeout(() => {
      let scrollTop;
      let scrollContainer;

      if (isInOverlay) {
        scrollContainer = register.parentElement;
        scrollTop = scrollContainer.scrollTop;
      } else {
        scrollContainer = window;
        scrollTop = shared.scrollTop - shared.getHeaderHeight();
      }

      scrollContainer.scroll({
        top:
          el.getBoundingClientRect().top +
          scrollTop -
          shared.adminBarHeight -
          20,
        behavior: 'smooth',
      });
    });
  };

  const changePages = (page, nextPage) => {
    // eslint-disable-next-line no-param-reassign
    page.style.overflow = 'visible';
    page.classList.remove(shared.CSS.active);
    nextPage.classList.add(shared.CSS.active);
    setTimeout(() => {
      // eslint-disable-next-line no-param-reassign
      page.style.overflow = '';
    }, 500);
  };

  const setBreadcrumb = (index) => {
    breadcrumbs.forEach((b) => b.classList.remove(shared.CSS.active));
    breadcrumbs[index].classList.add(shared.CSS.active);
  };

  const validateInput = (input) => {
    const classTarget = input.parentNode;
    let isValid = input.checkValidity();

    if (
      window.Registration.lang === 'pl' &&
      input.name === 'invoice_data[nip]' &&
      input.value.length > 0
    ) {
      isValid = validateNIP(input.value);
    }

    if (!isValid) {
      classTarget.classList.add(shared.CSS.invalid);
    } else {
      classTarget.classList.remove(shared.CSS.invalid);
    }

    return isValid;
  };

  const getOrderPrice = () => {
    const selectedRadios = registerPages[0].querySelectorAll(
      'input[data-price]:checked',
    );
    let totalValue = 0;

    [].forEach.call(selectedRadios, (input) => {
      totalValue += parseFloat(input.getAttribute('data-price'));
    });

    return totalValue;
  };

  const checkProducts = () => {
    const page = registerPages[0];
    const selectedRadios = page.querySelectorAll('input[data-price]:checked');

    if (selectedRadios.length < 1) {
      nextButton.setAttribute('disabled', '');
      return false;
    }
    nextButton.removeAttribute('disabled');
    return true;
  };

  const clearInput = (input) => {
    input.parentNode.classList.remove(shared.CSS.invalid);
  };

  const validateFields = () => {
    let valid = true;

    [].forEach.call(addressInputs, (input) => {
      if (!validateInput(input)) {
        valid = false;
      }
    });

    if (invoice.checked) {
      [].forEach.call(invoiceInputs, (input) => {
        if (!validateInput(input)) {
          valid = false;
        }
      });
    }

    [].forEach.call(agree, (input) => {
      if (!validateInput(input)) {
        valid = false;
      }
    });

    return valid;
  };

  [].forEach.call(productRows, (row) => {
    const radio = row.querySelector('input');
    row.addEventListener('mousedown', () => {
      if (radio.checked) {
        radio.uncheck = true;
      }
    });

    row.addEventListener('mouseup', () => {
      if (radio.uncheck === true) {
        setTimeout(() => {
          radio.uncheck = false;
          radio.checked = false;
          checkProducts();
        }, 1);
      }
    });
  });

  const formCheck = (event) => {
    if (currentPage === 0) {
      checkProducts();
    } else if (!/checkbox|radio|select/.test(event.target.type)) {
      if (event.target.value.length === 0) {
        clearInput(event.target);
      } else {
        validateInput(event.target);
      }
    } else if (event.target.checked) {
      clearInput(event.target);
    }
  };

  registerForm.addEventListener('input', formCheck);
  registerForm.addEventListener('focusout', (event) => {
    if (!/checkbox|radio|select/.test(event.target.type)) {
      validateInput(event.target);
    }
  });
  registerForm.addEventListener('change', formCheck);

  registerForm.addEventListener('submit', (event) => {
    event.preventDefault();
    if (currentPage === 1) {
      if (!validateFields()) {
        scrollToElement(
          registerPages[currentPage].querySelector(`.${shared.CSS.invalid}`),
        );
        return;
      }

      if (!checkProducts()) {
        return;
      }

      const total = getOrderPrice();
      if (total === 0) {
        register.classList.add('is-order-free');
      } else {
        register.classList.remove('is-order-free');
      }

      const request = new XMLHttpRequest();

      request.open('POST', window.Registration.ajaxUrl, true);

      request.onload = () => {
        overlay.classList.remove(shared.CSS.loading);
        if (request.status >= 200 && request.status < 400) {
          let json = {};
          try {
            json = JSON.parse(request.responseText);
          } catch (err) {
            // eslint-disable-next-line no-console
            console.log(err);
          }

          if (json.status === 'ok') {
            overlay.classList.add('is-order-ok');
            if (json.form !== undefined) {
              const currentForm = document.querySelector('#payment-form');
              if (currentForm !== null) {
                currentForm.parentNode.removeChild(currentForm);
              }
              document.body.insertAdjacentHTML('beforeend', json.form);
              setTimeout(() => {
                document.querySelector('#payment-form').submit();
              }, 2000);
            }
            registerForm.reset();
          } else if (json.status === 'no-room') {
            overlay.classList.add('is-order-no-room', 'is-error');
          } else {
            overlay.classList.add('is-order-error', 'is-error');
          }
        } else {
          overlay.classList.add('is-order-fatal-error', 'is-error');
        }
      };

      request.onerror = () => {
        overlay.classList.remove(shared.CSS.loading);
        overlay.classList.add('is-order-fatal-error', 'is-error');
      };

      const formData = new FormData(registerForm);
      request.send(formData);

      changePages(registerPages[currentPage], overlay);
      changePages(registerForm, overlay);
      currentPage = 2;
      overlay.classList.add(shared.CSS.loading);
      scrollToElement(overlay);
    }
  });

  nextButton.addEventListener('click', (event) => {
    event.preventDefault();

    if (nextButton.hasAttribute('disabled')) {
      return;
    }

    const nextPage = (currentPage + 1) % registerPages.length;
    changePages(registerPages[currentPage], registerPages[nextPage]);
    currentPage = nextPage;
    setBreadcrumb(currentPage);
    scrollToElement(breadcrumbsContainer);
  });

  invoice.addEventListener('change', () => {
    invoiceData.classList.toggle(shared.CSS.active);
  });

  [].forEach.call(moreTriggers, (trigger) => {
    trigger.addEventListener('click', (event) => {
      event.preventDefault();
      event.currentTarget.parentNode
        .querySelector('.c-register__agree-more')
        .classList.add(shared.CSS.active);

      event.currentTarget.parentNode.removeChild(event.currentTarget);
    });
  });

  [...backButtons].forEach((bt) =>
    bt.addEventListener('click', (e) => {
      e.preventDefault();
      const nextPage = Math.max(0, currentPage - 1);
      changePages(registerPages[currentPage], registerPages[nextPage]);
      currentPage = nextPage;
      setBreadcrumb(currentPage);
      scrollToElement(breadcrumbsContainer);

      if (currentPage < 2) {
        registerForm.classList.add(shared.CSS.active);
        setTimeout(() => {
          const classesToRemove = [
            'is-order-fatal-error',
            'is-order-no-room',
            'is-order-error',
            'is-order-ok',
            'is-order-free',
            'is-error',
          ];

          for (let i = 0; i < classesToRemove.length; i += 1) {
            overlay.classList.remove(classesToRemove[i]);
          }
        }, 600);
      } else {
        registerForm.classList.remove(shared.CSS.active);
      }
    }),
  );

  /**
   * DEBUG
   */

  /*
  registerPages[0].classList.remove(shared.CSS.active);
  registerPages[1].classList.add(shared.CSS.active);
  registerPages[0].querySelector('input[data-price]').checked = true;
  registerPages[1].querySelector('[name="address[email]"]').value = 'bjr@o2.pl';
  currentPage = 1;
  */
};
