function classAction(action, el, classes) {
  const classesArray = classes.split(' ');
  let elements = el;

  if (el.addEventListener !== undefined) {
    elements = [el];
  }

  for (let ii = 0; ii < elements.length; ii += 1) {
    for (let i = 0; i < classesArray.length; i += 1) {
      elements[ii].classList[action](classesArray[i]);
    }
  }
}

function elementAction(action, el, args) {
  let elements = el;

  if (el.addEventListener !== undefined) {
    elements = [el];
  }

  let argsArray = args;

  if (typeof argsArray === 'string') {
    argsArray = [argsArray];
  }

  for (let i = 0; i < elements.length; i += 1) {
    elements[i][action](...argsArray);
  }
}

function addClass(el, classes) {
  classAction('add', el, classes);
}

function removeClass(el, classes) {
  classAction('remove', el, classes);
}

function removeAttribute(el, attribute) {
  elementAction('removeAttribute', el, attribute);
}

function toggleClass(el, classes) {
  classAction('toggle', el, classes);
}

function on(events, el, callback, options) {
  const eventsArray = events.split(' ');
  for (let i = 0; i < eventsArray.length; i += 1) {
    elementAction('addEventListener', el, [eventsArray[i], callback, options]);
  }
}

function off(events, el, callback) {
  const eventsArray = events.split(' ');
  for (let i = 0; i < eventsArray.length; i += 1) {
    elementAction('removeEventListener', el, [eventsArray[i], callback]);
  }
}

function each(elements, callback) {
  [].forEach.call(elements, callback);
}

function createEvent(eventName) {
  let event;
  if (typeof Event === 'function') {
    event = new Event(eventName);
  } else {
    event = document.createEvent('Event');
    event.initEvent(eventName, true, true);
  }
  return event;
}

function now() {
  return 'now' in window.performance ? performance.now() : new Date().getTime();
}

function getCookie(name) {
  const v = document.cookie.match(`(^|;) ?${name}=([^;]*)(;|$)`);
  return v ? v[2] : null;
}

function setCookie(name, value, days) {
  const d = new Date();
  d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * days);
  document.cookie = `${name}=${value};path=/;expires=${d.toGMTString()}`;
}

function deleteCookie(name) {
  setCookie(name, '', -1);
}

function nextFrame(callback) {
  return window.requestAnimationFrame(() =>
    window.requestAnimationFrame(() => callback()),
  );
}

export {
  addClass,
  removeClass,
  toggleClass,
  on,
  off,
  each,
  createEvent,
  now,
  removeAttribute,
  getCookie,
  setCookie,
  deleteCookie,
  nextFrame,
};
