import { on } from '../helpers/shortcuts';
import shared from './shared';

export default () => {
  const accordionTitles = document.querySelectorAll('.c-accordion__item-title');

  if (accordionTitles.length < 1) {
    return;
  }

  const onAnimationEnd = (event) => {
    // eslint-disable-next-line no-param-reassign
    event.currentTarget.style.maxHeight = '';
  };

  [...accordionTitles].forEach((accordionTitle) => {
    const accordionRoot = accordionTitle.parentElement;
    const accordionContent = accordionRoot.querySelector(
      '.c-accordion__item-content',
    );

    accordionContent.parentElement.addEventListener(
      'transitionend',
      onAnimationEnd,
    );

    on('click', accordionTitle, (event) => {
      event.preventDefault();
      const isActive = accordionRoot.classList.contains(shared.CSS.active);

      if (!accordionContent) {
        accordionTitle.parentElement.classList.toggle(shared.CSS.active);
        return;
      }

      const height = accordionContent.scrollHeight;

      accordionContent.parentElement.style.maxHeight = `${height}px`;
      accordionRoot.classList.toggle(shared.CSS.active);

      if (isActive) {
        setTimeout(() => {
          accordionContent.parentElement.style.maxHeight = '0px';
        });
      }
    });
  });
};
