/* eslint-disable no-param-reassign */
import { on } from '../helpers/shortcuts';
import shared from './shared';

export default () => {
  const asideImages = document.querySelectorAll('.c-aside-image');
  const imagesData = [];

  [...asideImages].forEach((asideImage) => {
    const asideImageImage = asideImage.querySelector('.c-aside-image__image');

    if (asideImageImage) {
      imagesData.push({
        image: asideImageImage,
        container: asideImageImage.parentElement,
        isLeft: !asideImage.classList.contains('is-style-right'),
        isDimmed: false,
      });
    }
  });

  if (imagesData.length < 1) {
    return;
  }

  const DISTANCE = 20;
  const sidebarNavigation = document.querySelector('.c-sidebar-navigation');
  let sidebarNavTop = 0;
  let sidebarNavBottom = 0;

  const updateData = () => {
    imagesData.forEach((imageData) => {
      const { transform } = imageData.container.style;
      imageData.container.style.transform = '';
      const imageRect = imageData.image.getBoundingClientRect();
      imageData.container.style.transform = transform;

      imageData.width = imageRect.width;
      imageData.top = imageRect.top + shared.scrollTop;
      imageData.bottom = imageData.top + imageRect.height;
      imageData.center = imageData.top + imageRect.height * 0.5;
    });

    if (sidebarNavigation) {
      const sidebarRect =
        sidebarNavigation.firstElementChild.getBoundingClientRect();
      sidebarNavTop = sidebarRect.top;
      sidebarNavBottom = sidebarNavTop + sidebarRect.height;
    }
  };

  const updatePos = () => {
    if (shared.windowWidth < shared.breakpoints.xlarge) {
      return;
    }

    imagesData.forEach((imageData) => {
      const height = shared.windowHeight * 0.5;
      let progress = (imageData.center - (shared.scrollTop + height)) / height;

      if (progress < -1) {
        progress = -1;
      } else if (progress > 1) {
        progress = 1;
      }

      const distance = DISTANCE * progress;

      imageData.container.style.transform = `translateY(${distance}%)`;

      if (sidebarNavigation && imageData.isLeft) {
        const isOverlapping = !(
          imageData.top > shared.scrollTop + sidebarNavBottom ||
          imageData.bottom < shared.scrollTop + sidebarNavTop
        );

        if (!imageData.isDimmed && isOverlapping) {
          imageData.isDimmed = true;
          imageData.image.classList.add(shared.CSS.isDimmed);
        } else if (imageData.isDimmed && !isOverlapping) {
          imageData.isDimmed = false;
          imageData.image.classList.remove(shared.CSS.isDimmed);
        }
      }
    });
  };

  const updateDataAndPos = () => {
    if (shared.windowWidth >= shared.breakpoints.xlarge) {
      updateData();
      updatePos();
    }
  };

  updateDataAndPos();

  on('scroll', window, updatePos);
  on('load', window, updateDataAndPos);
  on('resize', window, updateDataAndPos);
};
