import shared from './shared';
import { lockViewport, unlockViewport } from '../helpers/viewport-lock';

export default () => {
  const overlays = document.querySelectorAll('.c-overlay');

  if (overlays.length < 1) {
    return;
  }

  [...overlays].forEach((overlay) => {
    const overlayClose = overlay.querySelector('.c-overlay__close');

    if (overlayClose) {
      overlayClose.addEventListener('click', (event) => {
        event.preventDefault();
        if (overlay.classList.contains('is-redirection')) {
          window.location = '/';
        } else {
          overlay.classList.remove(shared.CSS.active);
          unlockViewport(overlay);
        }
      });
    }
  });

  const registerFormOverlay = [...overlays].find((overlay) =>
    overlay.classList.contains('c-overlay--register-form'),
  );

  if (registerFormOverlay) {
    const registerOverlayTriggers = document.querySelectorAll(
      '[data-registration-form-overlay]',
    );
    [...registerOverlayTriggers].forEach((trigger) => {
      trigger.addEventListener('click', (event) => {
        event.preventDefault();
        lockViewport(registerFormOverlay);
        registerFormOverlay.classList.add(shared.CSS.active);
      });
    });
  }
};
