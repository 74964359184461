import ScrollBooster from 'scrollbooster';
import { each } from './shortcuts';

export default (container) => {
  let startX = 0;
  // eslint-disable-next-line no-new
  new ScrollBooster({
    viewport: container,
    content: container,
    mode: 'x',
    onUpdate: (data) => {
      // eslint-disable-next-line no-param-reassign
      container.scrollLeft = data.position.x;
    },
  });

  container.addEventListener('mousedown', () => {
    startX = container.scrollLeft;
  });

  const links = container.querySelectorAll('a');
  each(links, (link) => {
    link.addEventListener('click', (event) => {
      if (Math.abs(startX - container.scrollLeft) > 20) {
        event.preventDefault();
      }
    });
  });
};
