/* eslint-disable no-undef */

export default () => {
  const mapContainers = [
    ...document.querySelectorAll('.c-google-maps__map[data-json]'),
  ];
  if (mapContainers.length < 1 || !window?.APP_DATA?.google_maps_key) {
    return;
  }

  window.initGoogleMap = () => {
    mapContainers.forEach((mapContainer) => {
      const mapData = JSON.parse(mapContainer.dataset.json);

      if (!mapData) {
        return;
      }

      const map = new google.maps.Map(mapContainer, {
        center: { lat: parseFloat(mapData.lat), lng: parseFloat(mapData.lng) },
        zoom: parseInt(mapData.zoom, 10),
        scrollwheel: false,
        gestureHandling: 'greedy',
        panControl: true,
        isDraggable: true,
        styles: [
          {
            featureType: 'all',
            elementType: 'all',
            stylers: [
              {
                saturation: -100,
              },
            ],
          },
        ],
      });
      let currentInfoWindow = null;

      if (Array.isArray(mapData.markers) && mapData.markers.length > 0) {
        const addMarker = (markerData) => {
          const marker = new google.maps.Marker({
            map,
            position: {
              lat: parseFloat(markerData.lat),
              lng: parseFloat(markerData.lng),
            },
            title: '',
            icon: {
              url: window.APP_DATA.marker_code,
              scaledSize: new google.maps.Size(31.537, 40.462),
            },
          });

          const infowindow = new google.maps.InfoWindow({
            content: markerData.description,
          });

          marker.addListener('click', () => {
            if (currentInfoWindow) {
              currentInfoWindow.close();
              currentInfoWindow = null;
            }
            infowindow.open(map, marker);
            currentInfoWindow = infowindow;
          });
        };

        mapData.markers.forEach(addMarker);
      }
    });
  };

  const scriptTag = document.createElement('script');
  scriptTag.setAttribute('type', 'text/javascript');
  scriptTag.setAttribute(
    'src',
    `https://maps.googleapis.com/maps/api/js?key=${window.APP_DATA.google_maps_key}&callback=initGoogleMap`,
  );
  document.body.appendChild(scriptTag);
};
