import Swiper from 'swiper';

export default () => {
  const workMoreContainer = document.querySelector('.c-work__more');

  if (!workMoreContainer) {
    return;
  }

  const swiperContainer = workMoreContainer.querySelector('.swiper');
  const next = workMoreContainer.querySelector('.c-work__gallery-nav--next');
  const prev = workMoreContainer.querySelector('.c-work__gallery-nav--prev');
  let swiper = null;

  swiper = new Swiper(swiperContainer, {
    grabCursor: true,
    centeredSlides: true,
    slidesPerView: 'auto',
    loop: true,
    freeMode: true,
  });

  next.addEventListener('click', (event) => {
    event.preventDefault();
    if (swiper) {
      swiper.slideNext();
    }
  });

  prev.addEventListener('click', (event) => {
    event.preventDefault();
    if (swiper) {
      swiper.slidePrev();
    }
  });
};
