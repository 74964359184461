/*
  Project: Agrafa
  Author: Piotr Bajer
 */

import fix100vh from './fixes/100vh';
import header from './modules/header';
import asideImages from './modules/aside-images';
import accordions from './modules/accordions';
import galleries from './modules/galleries';
import map from './modules/map';
import footer from './modules/footer';
import anchors from './modules/anchors';
import newsletter from './modules/newsletter';
import register from './modules/register';
import overlay from './modules/overlay';
import workGallery from './modules/work-gallery';
import workMoreGallery from './modules/work-more-gallery';
import work from './modules/work';
import scrollPanes from './modules/scroll-panes';
import annotations from './modules/annotations';

fix100vh();
header();
asideImages();
accordions();
galleries();
map();
footer();
anchors();
newsletter();
register();
overlay();
workGallery();
workMoreGallery();
work();
scrollPanes();
annotations();
