import { setCookie, getCookie, on, createEvent } from '../helpers/shortcuts';
import shared from './shared';
import { lockViewport, unlockViewport } from '../helpers/viewport-lock';

export default () => {
  const header = document.querySelector('.c-header');

  if (!header) {
    return;
  }

  const headerMessage = header.querySelector('.c-header__message');

  if (headerMessage) {
    const headerMessageToggle = headerMessage.querySelector(
      '.c-header__message-button',
    );
    const messageCookie = `header-message-${
      (window.APP_DATA && window.APP_DATA.header_message_revision) || 0
    }`;

    headerMessageToggle.addEventListener('click', (event) => {
      event.preventDefault();
      document.documentElement.classList.remove('has-header-message');
      setCookie(messageCookie, 1, 30);
      window.dispatchEvent(createEvent('resize'));
    });

    if (!getCookie(messageCookie)) {
      document.documentElement.classList.add('has-header-message');
      window.dispatchEvent(createEvent('resize'));
    }
  }

  const sidebarNavigation = document.querySelector('.c-sidebar-navigation');

  if (sidebarNavigation) {
    let isMenuFixed = false;
    let navigationBottom = 0;
    const submenuTrigger = header.querySelector('.c-header__menu-trigger');
    const submenuButtons = sidebarNavigation.querySelectorAll(
      '.wp-block-button__link',
    );

    on('click', submenuButtons, (event) => {
      event.stopPropagation();

      if (
        event.currentTarget.href &&
        event.currentTarget.href.indexOf('#') > -1
      ) {
        event.preventDefault();
        if (
          document.documentElement.classList.contains(shared.CSS.submenuOpen)
        ) {
          submenuTrigger.dispatchEvent(createEvent('click'));
        }
      }
    });
    on('click', document.documentElement, () => {
      if (
        isMenuFixed &&
        document.documentElement.classList.contains(shared.CSS.submenuOpen)
      ) {
        unlockViewport(sidebarNavigation.firstElementChild);
        document.documentElement.classList.remove(shared.CSS.submenuOpen);
        submenuTrigger.classList.remove(shared.CSS.active);
      }
    });

    if (submenuTrigger) {
      on('click', submenuTrigger, (event) => {
        event.stopPropagation();
        document.documentElement.classList.toggle(shared.CSS.submenuOpen);
        submenuTrigger.classList.toggle(shared.CSS.active);
        if (
          document.documentElement.classList.contains(shared.CSS.submenuOpen)
        ) {
          lockViewport(sidebarNavigation.firstElementChild);
        } else {
          unlockViewport(sidebarNavigation.firstElementChild);
        }
      });
    }

    const updateNavigationData = () => {
      if (isMenuFixed) {
        return;
      }
      const navigationRect =
        sidebarNavigation.firstElementChild.getBoundingClientRect();

      navigationBottom =
        navigationRect.height + navigationRect.top + shared.scrollTop;
    };

    on('scroll', window, () => {
      if (shared.windowWidth >= shared.breakpoints.xlarge) {
        return;
      }

      if (!isMenuFixed && shared.scrollTop > navigationBottom) {
        sidebarNavigation.style.minHeight = `${
          sidebarNavigation.getBoundingClientRect().height
        }px`;
        sidebarNavigation.firstElementChild.style.transition = 'none';
        document.documentElement.classList.add(shared.CSS.submenuFixed);
        setTimeout(() => {
          sidebarNavigation.firstElementChild.style.transition = '';
        });
        isMenuFixed = true;
      } else if (isMenuFixed && shared.scrollTop < navigationBottom) {
        sidebarNavigation.style.minHeight = '';
        document.documentElement.classList.remove(shared.CSS.submenuFixed);
        document.documentElement.classList.remove(shared.CSS.submenuOpen);
        if (submenuTrigger) {
          submenuTrigger.classList.remove(shared.CSS.active);
        }
        isMenuFixed = false;
      }
    });

    updateNavigationData();

    on('resize', window, updateNavigationData);
    on('load', window, updateNavigationData);
  }
};
