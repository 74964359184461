import { on } from '../helpers/shortcuts';
import shared from './shared';

export default () => {
  const galleryContainers = document.querySelectorAll(
    '.wp-block-gallery.is-style-slideshow',
  );

  if (galleryContainers.length < 1) {
    return;
  }

  const slideTime = 4000;

  [...galleryContainers].forEach((galleryContainer) => {
    const slides = [...galleryContainer.querySelectorAll('.wp-block-image')];

    galleryContainer.classList.add('js-processed');

    if (slides.length < 1) {
      return;
    }

    slides[0].classList.add(shared.CSS.active);

    if (slides.length < 2) {
      return;
    }

    let slidesTimeout = null;
    let currentSlide = 0;

    const slideChange = () => {
      slides[currentSlide].classList.remove(shared.CSS.active);
      currentSlide = (currentSlide + 1) % slides.length;
      slides[currentSlide].classList.add(shared.CSS.active);

      clearTimeout(slidesTimeout);
      slidesTimeout = setTimeout(slideChange, slideTime);
    };

    on('load', window, () => {
      slidesTimeout = setTimeout(slideChange, slideTime);
    });
  });
};
