import shared from '../modules/shared';
import { on } from '../helpers/shortcuts';

export default () => {
  let orientationChanged = true;
  let refreshTimout = null;
  let adminBar = null;
  if (document.documentElement.classList.contains('has-admin-bar')) {
    adminBar = document.querySelector('#wpadminbar');
  }

  const updateHeight = () => {
    if (shared.isMobile) {
      if (orientationChanged) {
        shared.initialHeight = window.innerHeight;
        document.documentElement.style.setProperty(
          '--initial-height',
          `${shared.initialHeight}px`,
        );

        orientationChanged = false;
      }

      document.documentElement.style.setProperty(
        '--full-height',
        `${window.innerHeight}px`,
      );
    }

    if (adminBar !== null) {
      shared.adminBarHeight = adminBar.offsetHeight;
      document.documentElement.style.setProperty(
        '--admin-bar-height',
        `${shared.adminBarHeight}px`,
      );
    }

    shared.headerHeight = shared.getHeaderHeight();

    document.documentElement.style.setProperty(
      '--header-height',
      `${shared.headerHeight}px`,
    );
  };

  const updateHeightWithDelay = () => {
    clearTimeout(refreshTimout);
    refreshTimout = setTimeout(updateHeight, 200);
  };

  updateHeight();

  window.addEventListener('orientationchange', () => {
    orientationChanged = true;
    updateHeightWithDelay();
  });

  on('resize', window, updateHeight);
  on('load', window, updateHeight);
};
